import { Router, ActivatedRouteSnapshot, UrlSegment } from '@angular/router';
import { Injectable } from '@angular/core';
import { JwtService } from '@sharedServices/jwt.service';
import { UserService } from '@userServices/user.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(
    private _jwt: JwtService,
    private _router: Router,
    private _userService: UserService
  ) { }

  /**
   * @summary Checks if the current user can activate or view the requested route
   * @param route The route snapshot to check permissions on
   */
  public canActivate(route: ActivatedRouteSnapshot): boolean {
    return this.authorize(route?.data);
  }

  /**
   * @summary Checks if the current user can activate or view a child of a parent route
   * @param route The route snapshot to check permissions on
   */
  public canActivateChild(route: ActivatedRouteSnapshot): boolean {
    return this.authorize(route?.data);
  }

  /**
   * @summary Checks if the current user can activate and load a module based on their permissions
   * @param route The route snapshot to check permissions on
   */
  public canLoad(route: any, segments: UrlSegment[]): boolean {
    return this.authorize(route?.data);
  }

  /**
   * @summary authorizes the current JWT against the route being viewed and permissions required
   * @param data route?.data values which are permissions to check
   */
  private authorize(data?: any): boolean {
    // Validate the users JWT
    if (this._jwt.isAuthorized() === false) {
      return this.fail();
    }

    //validate any required role
    const userRole = this._jwt.getAuthorizedRole();
    
    if (data?.role && data.role != userRole) {
      return this.fail();
    }

    // Validate any required permissions
    if (data?.permissions?.length &&
      this._userService.hasPermissions(data.permissions) === false) {
      return this.fail();
    }

    return true;
  }

  private fail(): boolean {
    this._router.navigateByUrl('/');

    return false;
  }
}
